import CouponApi from '@/api/coupon'
const couponModule = {
  namespaced: true,
  state: {
    systemCoupons: {},
    systemCoupon: {},
    myCoupons: {},
    myCoupon: {},
    usedCoupons: {},
    usedPoints: {},
    purchaseList: {}
  },
  mutations: {
    SET_SYSTEM_COUPONS (state, value) {
      state.systemCoupons = value
    },
    SET_SYSTEM_COUPON (state, value) {
      state.systemCoupon = value
    },
    SET_MY_COUPONS (state, value) {
      state.myCoupons = value
    },
    SET_MY_COUPON (state, value) {
      state.myCoupon = value
    },
    SET_USED_COUPONS (state, value) {
      state.usedCoupons = value
    },
    SET_USED_POINTS (state, value) {
      state.usedPoints = value
    },
    SET_PURCHASE_LIST (state, value) {
      state.purchaseList = value
    }
  },
  actions: {
    clearSystemCoupon ({ rootState: { Auth: { auth } }, commit }) {
      commit('SET_SYSTEM_COUPON', {})
    },
    clearMyCoupon ({ rootState: { Auth: { auth } }, commit }) {
      commit('SET_MY_COUPON', {})
    },
    async fetchSystemCoupon ({ rootState: { Auth: { auth } }, commit }, params) {
      try {
        const result = await CouponApi.fetchSystemCoupon(params, auth.token)
        commit('SET_SYSTEM_COUPONS', result.data)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchSystemCouponById ({ rootState: { Auth: { auth } }, commit }, payload) {
      try {
        const result = await CouponApi.fetchSystemCouponById(payload.id, payload.params, auth.token)
        commit('SET_SYSTEM_COUPON', result.data)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchMyCoupon ({ rootState: { Auth: { auth } }, commit }, params) {
      try {
        const result = await CouponApi.fetchMyCoupon(params, auth.token)
        commit('SET_MY_COUPONS', result.data)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchMyCouponById ({ rootState: { Auth: { auth } }, commit }, payload) {
      try {
        const result = await CouponApi.fetchMyCouponById(payload.id, payload.params, auth.token)
        commit('SET_MY_COUPON', result.data)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchUsedCoupon ({ rootState: { Auth: { auth } }, commit }, params) {
      try {
        const result = await CouponApi.fetchUsedCoupon(params, auth.token)
        commit('SET_USED_COUPONS', result.data)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchUsedPoint ({ rootState: { Auth: { auth } }, commit }, params) {
      try {
        const result = await CouponApi.fetchUsedPoint(params, auth.token)
        commit('SET_USED_POINTS', result.data)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async collectCoupon ({ rootState: { Auth: { auth } }, commit }, params) {
      try {
        const result = await CouponApi.collectCoupon(params, auth.token)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async redeemCoupon ({ rootState: { Auth: { auth } }, commit }, params) {
      try {
        const result = await CouponApi.redeemCoupon(params, auth.token)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async checkAdminCode ({ rootState: { Auth: { auth } }, commit }, params) {
      try {
        const result = await CouponApi.checkAdminCode(params, auth.token)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchPurchaseList ({ rootState: { Auth: { auth } }, commit }, params) {
      try {
        const result = await CouponApi.fetchPurchaseList(params, auth.token)
        commit('SET_PURCHASE_LIST', result.data)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
export default couponModule
