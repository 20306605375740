export default function auth ({ next, store, to }) {
  const { auth, user } = store.state.Auth
  if (!auth.isLoggedIn || !user) {
    return next({
      name: 'Login',
      replace: true
    })
  }

  return next()
}
