export default {
  common: {
    level: 'ระดับ{level}',
    baht: 'บาท',
    purchaseBalance: 'ยอดการใช้บริการสะสม',
    memberId: 'รหัสสมาชิก : {memberId}',
    changeLanguage: 'เปลี่ยนภาษา',
    done: 'ยืนยัน',
    used: 'ใช้แล้ว',
    expired: 'หมดอายุ',
    sortDate: 'เรียงตามวันที่',
    moreDetails: 'รายละเอียด',
    couponId: 'คูปอง ID : {couponId}',
    noData: 'ไม่มีข้อมูล',
    usedAt: 'เวลาที่ใช้คูปองสำเร็จ : {usedAt}'
  },
  loginPage: {
    title: 'ลงทะเบียน',
    howto: 'วิธีได้รับรหัสลงทะเบียน ?',
    registrationCodes: 'รหัสลงทะเบียน',
    enterCode: 'ใส่รหัส',
    error: '*รหัสสำหรับลงทะเบียนไม่ถูกต้อง'
  },
  howtoPage: {
    title: 'วิธีได้รับรหัสลงทะเบียน'
  },
  registerPage: {
    titleEvent: 'สมาชิกจากกิจกรรม',
    titlePackage: 'ลงทะเบียนสมาชิก',
    firstName: 'ชื่อ',
    lastName: 'นามสกุล',
    phoneNumber: 'เบอร์โทรศัพท์',
    gender: 'เพศ',
    dateOfBirth: 'วันเกิด',
    address: 'ที่อยู่',
    addressDetail: 'บ้านเลขที่ 12/34 soi 2',
    province: 'จังหวัด',
    district: 'เขต/อำเภอ',
    subDistrict: 'แขวง/ตำบล',
    postcode: 'รหัสไปรษณีย์',
    healthCondition: 'โรคประจำตัว หรือปัญหาสุขภาพ',
    interestHealthcare: 'ความสนใจด้านสุขภาพของท่าน',
    register: 'ลงทะเบียน',
    male: 'ชาย',
    female: 'หญิง',
    preferNotToSay: 'ไม่ประสงค์จะระบุ',
    other: 'อื่น ๆ',
    accept1: 'ฉันยอมรับ ',
    accept2: 'เงื่อนไขและข้อกำหนด',
    newsletterAccept: 'ฉันยอมรับที่จะรับข่าวสาร โปรโมชั่น และการแจ้งเตือนจากโรงพยาบาล',
    acceptDetail: 'โปรดยอมรับเงื่อนไขและข้อกำหนดเพื่อดำเนินการต่อ',
    mustAccpet: 'กรุณาอ่านและกดปุ่มยอมรับข้อกำหนดเงื่อนไข',
    emailExist: 'email ของท่านได้ถูกใช้งาน กรุณากรอก email ใหม่',
    phoneExist: 'เบอร์โทรศัพท์ ของท่านได้ถูกใช้งาน กรุณากรอก เบอร์โทรศัพท์ ใหม่',
    nameExist: 'ชื่อ-นามสกุล ของท่านได้ถูกใช้งาน กรุณากรอก ชื่อ-นามสกุล ใหม่'
  },
  condition: {
    title: 'เงื่อนไขและข้อกำหนด'
  },
  homePage: {
    expirationDate: 'หมดอายุวันที่ {expiredAt}',
    myCoupon: 'คูปองของฉัน',
    exclusiveBenefits: 'รายการสิทธิประโยชน์',
    couponBenefits: 'รายการคูปอง',
    levelup: '{balance} บาท เพื่อเปลี่ยนเป็น ระดับ{level}'
  },
  howtoStandardPage: {
    title: 'รหัสลงทะเบียนบัตรสมาชิกทั่วไป',
    action: 'กรอกรหัสลงทะเบียน'
  },
  menu: {
    home: 'หน้าแรก',
    account: 'บัญชีผู้ใช้',
    myCoupon: 'คูปองของฉัน',
    history: 'ประวัติการใช้'
  },
  rewardDetailPage: {
    title: 'รายการสิทธิประโยชน์',
    exclusiveBenefits: 'ข้อเสนอและสิทธิประโยชน์',

    levelup1: 'สะสมยอดการใช้บริการอีก',
    levelup2: ' {balance} บาท ',
    levelup3: 'ภายใน',
    levelup4: '{expiredAt} เพื่อเป็นสมาชิกระดับ{level}',
    note: 'หมายเหตุ: ระบบใช้เวลาประมาณ 14 วัน ในการอัปเดทยอดการใช้บริการ'
  },
  memberCardModal: {
    expirationDate: 'บัตรหมดอายุ {expiredAt}',
    renewMemberCard: 'ต่ออายุบัตรสมาชิก'
  },
  renewPage: {
    title: 'ต่ออายุบัตรสมาชิก',
    accept: 'ยอมรับและต่ออายุบัตร'
  },
  accountPage: {
    title: 'บัญชีผู้ใช้',
    registerDate: 'วันที่ลงทะเบียน : {registerDate}',
    profile: 'ข้อมูลส่วนตัว',
    editProfile: 'แก้ไขข้อมูลส่วนตัว',
    condition: 'เงื่อนไขและข้อกำหนด',
    name: 'ชื่อ - นามสกุล',
    phoneNumber: 'เบอร์โทรศัพท์',
    gender: 'เพศ',
    dateOfBirth: 'วันเกิด',
    address: 'ที่อยู่',
    deleteAccount: 'ลบบัญชีผู้ใช้'
  },
  couponDetail: {
    used: 'ใช้แล้ว',
    expirationDate: 'หมดอายุวันที่ {expiredAt}',
    condition: 'เงื่อนไข',
    claim: 'เก็บคูปอง',
    redeem: 'ใช้สิทธิ',

    times: 'ครั้งที่',
    usedDate: 'วันที่ใช้งาน',
    usedTime: 'เวลา'
  },
  claimModal: {
    success: 'ยินดีด้วย',
    successDetail: 'คุณได้เก็บคูปองสำเร็จแล้ว',
    btnMyCoupon: 'ไปที่ คูปองของฉัน',
    btnBackToHome: 'กลับหน้าแรก'
  },
  myCouponPage: {
    title: 'ข้อเสนอ และสิทธิประโยชน์',
    exclusive: 'สิทธิพิเศษเฉพาะท่าน',
    special: 'ข้อเสนอพิเศษ'
  },
  adminModal: {
    progress: 'กำลังทำรายการ...',
    progressDetail: 'โปรดรอให้เจ้าที่ยืนยันการรับสิทธิใช้งานคูปอง',
    adminCode: 'รหัสสำหรับเจ้าหน้าที่'
  },
  couponUsedModal: {
    success: 'สำเร็จ',
    successDetail: 'สิทธิพิเศษของท่านถูกใช้งานเรียบร้อยแล้ว',
    btnHistory: 'ไปที่ประวัติการใช้งาน',
    btnBackToHome: 'กลับหน้าแรก',
    fail: 'ไม่สำเร็จ',
    failDetail: 'เกิดข้อผิดพลาดกรุณาตรวจสอบและลองใหม่อีกครั้ง',
    btnTryAgain: 'ลองอีกครั้ง'
  },
  historyPage: {
    title: 'ประวัติการใช้งาน',
    couponUsed: 'ประวัติการใช้สิทธิพิเศษ',
    purchaseList: 'ประวัติยอดการใช้บริการ',
    lastUsedAt: 'ใช้ครั้งล่าสุด {lastUsedAt}',
    dateAt: 'วันที่ {dateAt}'
  },
  editProfile: {
    title: 'แก้ไขข้อมูลส่วนตัว',
    finishEditing: 'แก้ไข'
  },
  expiredMemberCard: {
    addMemberCard: 'เพิ่มบัตรสมาชิกใบใหม่',
    expired: 'บัตรสมหาชิกของคุณหมดอายุแล้ว'
  },
  renewModal: {
    register: 'ลงทะเบียน',
    registerDetail: 'โปรดใส่รหัสเพื่อลงทะเบียน',
    registerCode: 'รหัสลงทะเบียน',
    newMemberCard: 'บัตรสมาชิกใบใหม่',
    pendingRegisterCode: 'รอรับรหัสลงทะเบียน',
    contactSupport: 'ถ้าหากไม่ได้รับรหัสลงทะเบียนภายใน 3 วัน โปรดติดต่อเจ้าหน้าที่',
    requestNewMemberCard: 'ขอรหัสลงทะเบียนเพื่อสมัครบัตรสมาชิกใบใหม่',
    btnContactSupport: 'ติดต่อเจ้าหน้าที่',
    btnRequestRegisterCode: 'ขอรหัสลงทะเบียน'
  }
}
