export default {
  common: {
    level: '{level} Level',
    baht: 'THB',
    purchaseBalance: 'Purchase balance',
    memberId: 'Member ID : {memberId}',
    changeLanguage: 'Change Language',
    done: 'DONE',
    used: 'Used',
    expired: 'Expired',
    sortDate: 'Date added',
    moreDetails: 'More details',
    couponId: 'Coupon ID : {couponId}',
    noData: 'No Data',
    usedAt: 'Used At : {usedAt}'
  },
  loginPage: {
    title: 'Registration',
    howto: 'How to get registration codes',
    registrationCodes: 'Registration codes',
    enterCode: 'ENTER CODE',
    error: '*Registration codes is incorrect'
  },
  howtoPage: {
    title: 'How to get registration codes'
  },
  howtoStandardPage: {
    title: 'Standard Membership Registration',
    action: 'Enter code'
  },
  registerPage: {
    titleEvent: 'Member registration',
    titlePackage: 'Member registration',
    firstName: 'First Name',
    lastName: 'Last Name',
    phoneNumber: 'Phone Number',
    gender: 'Gender',
    dateOfBirth: 'Date of Birth',
    address: 'Address',
    addressDetail: 'Example 12/34 soi 2',
    province: 'Province',
    district: 'District',
    subDistrict: 'Sub-district',
    postcode: 'Postcode',
    healthCondition: 'Health conditions & Diseases',
    interestHealthcare: 'Interest in healthcare information',
    register: 'REGISTER',
    male: 'Male',
    female: 'Female',
    preferNotToSay: 'Prefer not to say',
    other: 'Other',
    accept1: 'I accept the ',
    accept2: 'Terms & Conditions',
    newsletterAccept: 'I accept to get news, promotion and notification from **Hospital Name**.',
    acceptDetail: 'Please accept Terms & Conditions in order to proceed',
    mustAccpet: 'Please read and accept the Terms & Conditions.',
    emailExist: 'email is already exists.',
    phoneExist: 'phone number is already exists',
    nameExist: 'name is already exists'
  },
  condition: {
    title: 'Terms & Conditions'
  },
  homePage: {
    expirationDate: 'Expiration {expiredAt}',
    myCoupon: 'My Coupon',
    exclusiveBenefits: 'Exclusive benefits',
    couponBenefits: 'Coupon Benefits',
    levelup: '{balance} THB to {level} Level'

  },
  menu: {
    home: 'Home',
    account: 'Account',
    myCoupon: 'My Coupon',
    history: 'History'
  },
  rewardDetailPage: {
    title: 'Exclusive Benefits',
    exclusiveBenefits: 'Exclusive benefits',

    levelup1: 'Spend',
    levelup2: ' {balance} THB ',
    levelup3: 'more within',
    levelup4: '{expiredAt} to become {level} level',
    note: 'หมายเหตุ: ระบบใช้เวลาประมาณ 14 วัน ในการอัปเดทยอดการใช้บริการ'
  },
  memberCardModal: {
    expirationDate: 'Expiration {expiredAt}',
    renewMemberCard: 'Renew Membership'
  },
  renewPage: {
    title: 'Renew Membership',
    accept: 'ACCEPT AND RENEW'
  },
  accountPage: {
    title: 'Account',
    registerDate: 'Registered Date : {registerDate}',
    profile: 'Profile',
    editProfile: 'Edit profile',
    condition: 'Terms & Conditions',
    name: 'Full Name',
    phoneNumber: 'Phone Number',
    gender: 'Gender',
    dateOfBirth: 'Date of Birth',
    address: 'Address',
    deleteAccount: 'DELETE ACCOUNT'
  },
  couponDetail: {
    used: 'Used',
    expirationDate: 'Expiration {expiredAt}',
    condition: 'Terms & Conditions',
    claim: 'CLAIM',
    redeem: 'REDEEM',

    times: 'Number',
    usedDate: 'Used Date',
    usedTime: 'Time'
  },
  claimModal: {
    success: 'Congrats!',
    successDetail: 'You have successfully redeemed your coupon',
    btnMyCoupon: 'GO TO MY COUPON',
    btnBackToHome: 'BACK TO HOME'
  },
  myCouponPage: {
    title: 'My Coupon',
    exclusive: 'Exclusive benefits',
    special: 'Special coupon'
  },
  adminModal: {
    progress: 'On Progress...',
    progressDetail: 'Waiting for staff to confirm..',
    adminCode: 'Admin code'
  },
  couponUsedModal: {
    success: 'Done',
    successDetail: 'You successfully used this coupon.',
    btnHistory: 'GO TO HISTORY',
    btnBackToHome: 'BACK TO HOME',
    fail: 'We are sorry',
    failDetail: 'Something went wrong. Please try agin.',
    btnTryAgain: 'TRY AGAIN'
  },
  historyPage: {
    title: 'History',
    couponUsed: 'Used coupon History',
    purchaseList: 'Purchased History',
    lastUsedAt: 'Date last used {lastUsedAt}',
    dateAt: 'Date {dateAt}'
  },
  editProfile: {
    title: 'Edit Profile',
    finishEditing: 'EDIT'
  },
  expiredMemberCard: {
    addMemberCard: 'Add new member card',
    expired: 'Your member card has already expired.'
  },
  renewModal: {
    register: 'Registration',
    registerDetail: 'Please enter the registration code.',
    registerCode: 'Registration codes',
    newMemberCard: 'New member card',
    pendingRegisterCode: 'Pending for code...',
    contactSupport: "If you didn't get code within 3 days.\nPlease contact support",
    requestNewMemberCard: 'Request registration code for add a new member card.',
    btnContactSupport: 'CONTACT SUPPORT',
    btnRequestRegisterCode: 'REQUEST CODE'
  }
}
