import Vue from 'vue'
import Buefy from 'buefy'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VueSpinners } from '@saeris/vue-spinners'
import VueCountdown from '@chenfengyuan/vue-countdown'
import { library } from '@fortawesome/fontawesome-svg-core'
// internal icons
import {
  faCopy
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueWait from 'vue-wait'
import Fragment from 'vue-fragment'
import InlineSvg from 'vue-inline-svg'
import VueClipboard from 'vue-clipboard2'
import numeral from 'numeral'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import './vee-validate'

library.add(faCopy)
Vue.component('vue-fontawesome', FontAwesomeIcon)

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas'
})
Vue.use(Fragment.Plugin)
Vue.use(VueWait)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('InlineSvg', InlineSvg)
Vue.component(VueCountdown.name, VueCountdown)
Vue.use(VueSpinners)
Vue.use(VueClipboard)

Vue.filter('formatNumber', (value) => {
  return numeral(value).format('0,0')
})

Vue.config.productionTip = false

const initializeApp = () => {
  new Vue({
    router,
    store,
    i18n,
    wait: new VueWait(),
    render: h => h(App)
  }).$mount('#app')
}

initializeApp()
