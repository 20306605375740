import UserApi from '@/api/user'
import moment from 'moment'

const authModule = {
  namespaced: true,
  state: {
    lineUserId: '',
    auth: {
      isLoggedIn: false,
      token: null
    },
    user: null,
    event: null,
    memberCard: null,
    code: null
  },
  getters: {
    userPackage ({ user }) {
      return (user && user.user_package) || {}
    },
    pointReceive (_, { userPackage }) {
      return (userPackage && userPackage.point_receive) || 0
    },
    isMemberCardExpired (_, { userPackage }) {
      return (userPackage && userPackage.is_member_card_expired)
    },
    isPackageExpired (_, { userPackage }) {
      return (userPackage && userPackage.is_package_expired)
    },
    isAbleToRenew (_, { userPackage }) {
      const expiredAt = (userPackage && userPackage.expired_at)
      if (!expiredAt) {
        return false
      }
      console.log(expiredAt)
      const expiredStart = moment((expiredAt) || Date.now()).subtract(30, 'days')
      const expiredEnd = moment((expiredAt) || Date.now())
      const now = moment(Date.now())
      console.log('expiredStart:', expiredStart.toLocaleString())
      console.log('now:', now.toLocaleString())
      console.log('expiredEnd:', expiredEnd.toLocaleString())
      return now.isBetween(expiredStart, expiredEnd) && !userPackage.is_package_expired
    },
    eventPackage (_, { userPackage }) {
      return (userPackage && userPackage.event_package) || {}
    },
    currentMemberCard (_, { userPackage }) {
      return (userPackage && userPackage.current_member_card) || {}
    },
    nextMemberCard (_, { userPackage }) {
      return (userPackage && userPackage.next_member_card) || {}
    },
    memberCards (_, { eventPackage }) {
      return (eventPackage && eventPackage.member_cards) || []
    },
    nextPointLevel (_, { nextMemberCard }) {
      return (nextMemberCard && nextMemberCard.point) || 0
    },
    remainPointNextLevel (_, { nextPointLevel, pointReceive }) {
      return nextPointLevel - pointReceive
    },
    progressMemberLevel (_, { pointReceive, nextPointLevel }) {
      return Math.ceil(pointReceive / nextPointLevel * 100)
    }
  },
  mutations: {
    SET_AUTH (state, value) {
      state.auth = {
        isLoggedIn: true,
        token: value.token
      }
    },
    SET_LINE_USER (state, value) {
      state.lineUserId = value
    },
    SET_USER (state, value) {
      state.user = value
    },
    SET_MEMBER_CARD (state, value) {
      state.memberCard = value
    },
    SET_EVENT (state, value) {
      state.event = value
    },
    SET_CODE (state, value) {
      state.code = value
    }
  },
  actions: {
    updateLineUser (context, payload) {
      context.commit('SET_LINE_USER', payload)
    },
    async register ({ state }, params) {
      try {
        const result = await UserApi.register(params)
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async registerPackage ({ state }, params) {
      try {
        const result = await UserApi.registerPackage(params)
        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async login ({ commit }, params) {
      try {
        const result = await UserApi.login(params)
        commit('SET_AUTH', result.data)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchCustomer ({ state, commit }) {
      try {
        const result = await UserApi.fetchCustomer(state.auth.token)
        commit('SET_USER', result.data)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchMemberCard ({ state, commit }) {
      try {
        const result = await UserApi.fetchMemberCard(state.auth.token)
        const { results } = result.data
        if (results && results.length > 0) {
          commit('SET_MEMBER_CARD', results[results.length - 1])
        }
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async editCustomer ({ state, commit }, params) {
      try {
        const result = await UserApi.editCustomer(params, state.auth.token, state.user)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async checkEventMember ({ state, commit }, code) {
      try {
        console.log(code)
        const result = await UserApi.checkEventMember(code.trim())
        const event = result.data
        commit('SET_EVENT', event)
        commit('SET_CODE', code.trim())
        return Promise.resolve(event)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async redeemPoint ({ state, commit }, params) {
      try {
        const result = await UserApi.redeemPoint(params, state.auth.token)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // renew
    async requestPackage ({ state, commit }, params) {
      try {
        const result = await UserApi.requestPackage(params, state.auth.token)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async renewPackage ({ state, commit }, params) {
      try {
        const result = await UserApi.renewPackage(params, state.auth.token)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async renewMember ({ state, commit }, params) {
      try {
        const result = await UserApi.renewMember(params, state.auth.token)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // question
    async fetchHealthQuestion ({ state }) {
      try {
        const result = await UserApi.fetchHealthQuestion()
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchInterestQuestion ({ state }) {
      try {
        const result = await UserApi.fetchInterestQuestion()
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchUserHealthQuestion ({ state }) {
      try {
        const result = await UserApi.fetchUserHealthQuestion(state.auth.token)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchUserInterestQuestion ({ state }) {
      try {
        const result = await UserApi.fetchUserInterestQuestion(state.auth.token)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async postHealthQuestion ({ state, commit }, params) {
      try {
        const result = await UserApi.postHealthQuestion(params, state.auth.token)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async postInterestQuestion ({ state, commit }, params) {
      try {
        const result = await UserApi.postInterestQuestion(params, state.auth.token)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async putHealthQuestion ({ state, commit }, params) {
      try {
        console.log(params)
        const result = await UserApi.putHealthQuestion(params.question_id, { ans_choice: params.ans_choice }, state.auth.token)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async putInterestQuestion ({ state, commit }, params) {
      try {
        const result = await UserApi.putInterestQuestion(params.question_id, { ans_choice: params.ans_choice }, state.auth.token)
        return Promise.resolve(result.data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
export default authModule
