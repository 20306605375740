<template>
  <div class="is-flex justify-center">
    <bounce-loader color="#ffffff" />
  </div>
</template>

<script>
export default {
  name: 'BaseLoading'
}
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  z-index: 30;
}

.spin-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
</style>
