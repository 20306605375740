import { extend, localize } from 'vee-validate'
import { required, digits, length, min } from 'vee-validate/dist/rules'

extend('required', required)
extend('digits', digits)
extend('length', length)
extend('min', min)

localize({
  en: {
    fields: {
      firstName: {
        required: 'กรุณากรอก ชื่อ'
      },
      lastName: {
        required: 'กรุณากรอก นามสกุล'
      },
      birthDate: {
        required: 'กรุณากรอก วัน/เดือน/ปีเกิด'
      },
      fullName: {
        required: 'กรุณากรอก ชื่อ - นามสกุลผู้รับ'
      },
      mobile: {
        required: 'กรุณากรอก เบอร์โทรศัพท์',
        length: 'กรุณากรอก เบอร์โทรศัพท์ 10 หลัก',
        digits: 'กรุณากรอก เบอร์โทรศัพท์ 10 หลัก',
        min: 'กรุณากรอก เบอร์โทรศัพท์ 10 หลัก'
      },
      address: {
        required: 'กรุณากรอก ที่อยู่ปัจจุบัน'
      },
      province: {
        required: 'กรุณากรอก จังหวัด'
      },
      district: {
        required: 'กรุณากรอก เขต/อำเภอ'
      },
      subDistrict: {
        required: 'กรุณากรอก แขวง/ตำบล'
      },
      road: {
        required: 'กรุณากรอก ถนน'
      },
      postCode: {
        required: 'กรุณากรอก รหัสไปรษณีย์',
        digits: 'กรุณากรอก รหัสไปรษณีย์ 5 หลัก'
      },
      gender: {
        required: 'กรุณาเลือก เพศ'
      },
      isOrganizeMember: {
        required: 'กรุณาตอบคำถาม'
      }
    }
  }
})
