export default function auth ({ next, store, to }) {
  const { event } = store.state.Auth
  if (!event) {
    return next({
      name: 'Login',
      replace: true
    })
  }

  return next()
}
