import axios from './axios'

export default {
  register (params) {
    return axios.post('account/register_event_member/', params)
  },
  registerPackage (params) {
    return axios.post('account/register_package_member/', params)
  },
  login (params) {
    return axios.post('account/login/', params)
  },
  fetchCustomer (token) {
    return axios.get('account/me/', {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  fetchMemberCard (token) {
    return axios.get('crm/me/member_card/', {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  editCustomer (params, token, user) {
    return axios.put(`account/me/${user.id}/`, params, {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  checkEventMember (code) {
    return axios.get('/member/check_event_member/', {
      params: {
        code
      }
    })
  },
  redeemPoint (params, token) {
    return axios.post('crm/redeem/point/', params, {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },

  // renew
  requestPackage (params, token) {
    return axios.post('member/user/request/package/', params, {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },

  renewPackage (params, token) {
    return axios.post('member/user/renew/package/', params, {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },

  renewMember (params, token) {
    return axios.post('member/user/extend/member_card/', params, {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },

  // question
  fetchHealthQuestion () {
    return axios.get('member/question/health/')
  },
  fetchInterestQuestion () {
    return axios.get('member/question/interest/')
  },
  fetchUserHealthQuestion (token) {
    return axios.get('member/user/question/health/', {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  fetchUserInterestQuestion (token) {
    return axios.get('member/user/question/interest/', {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  postHealthQuestion (params, token) {
    return axios.post('member/user/question/health/', params, {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  postInterestQuestion (params, token) {
    return axios.post('member/user/question/interest/', params, {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  putHealthQuestion (id, params, token) {
    console.log(params)
    return axios.put(`member/user/question/health/${id}/`, params, {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  putInterestQuestion (id, params, token) {
    return axios.put(`member/user/question/interest/${id}/`, params, {
      headers: {
        Authorization: `token ${token}`
      }
    })
  }
}
