import axios from './axios'

export default {
  fetchSystemCoupon (params, token) {
    return axios.get('member/system/coupon/', {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  fetchSystemCouponById (id, params, token) {
    return axios.get(`member/system/coupon/${id}/`, {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  fetchMyCoupon (params, token) {
    return axios.get('member/user/coupon/', {
      params,
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  fetchMyCouponById (id, params, token) {
    return axios.get(`member/user/coupon/${id}/`, {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  fetchUsedPoint (params, token) {
    return axios.get('crm/me/point/', {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  collectCoupon (params, token) {
    return axios.post('member/user/collect/coupon/', params, {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  redeemCoupon (params, token) {
    return axios.post('member/user/redeem/coupon/', params, {
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  checkAdminCode (params, token) {
    return axios.get('account/check_admin/', {
      params,
      headers: {
        Authorization: `token ${token}`
      }
    })
  },
  fetchPurchaseList (params, token) {
    return axios.get('member/user/purchase/', {
      params,
      headers: {
        Authorization: `token ${token}`
      }
    })
  }
}
