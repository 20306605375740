let baseUrl = 'https://api.yim-card.plaping-dc.com/'
baseUrl = 'https://api.yim-card.plaping-dc.com/'
const standardCode = 'SL64'

if (process.env.NODE_ENV === 'production') {
  baseUrl = 'https://api.yim-card.plaping-dc.com/'
}
export {
  baseUrl,
  standardCode
}
